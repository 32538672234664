import React from 'react';

class LoginForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = { login: '', password: '', error: '', disableSubmit: true };

		this.handleLoginChange = this.handleLoginChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.target);

		fetch('/login/submit', {
			method: 'POST',
			body: data,
		})
		.then(response => response.json())
		.then(data => {
			if (data.result) {
				window.location.reload();
			} else {
				this.setState({error: data.message});
			}
		});
	}

	handleLoginChange(event) {
		this.setState({
			login: event.target.value,
			disableSubmit: !event.target.value || !this.state.password
		});
	}

	handleDismiss(event) {
		this.setState({
			error: ''
		});
	}

	handlePasswordChange(event) {
		this.setState({
			password: event.target.value,
			disableSubmit: !this.state.login || !event.target.value
		});
	}

	render() {
		return (
			<form onSubmit={this.handleSubmit} acceptCharset="utf-8">
				<h3 className="form-title font-green">Вход</h3>
		        <div className="form-group">
		            <label htmlFor="login" className="control-label">
		            	Имя пользователя
		            </label>
		            <input id="login" name="login" className="form-control form-control-solid placeholder-no-fix" value={this.state.login} onChange={this.handleLoginChange}/>
		        </div>
		        <div className="form-group">
		            <label htmlFor="password" className="control-label">
		            	Пароль
		            </label>
		            <input id="password" name="password" className="form-control form-control-solid placeholder-no-fix" type="password" value={this.state.password} onChange={this.handlePasswordChange}/>
				</div>
				<div className="alert alert-danger login-error" style={{display: this.state.error ? 'block' : 'none'}}>
					<button type="button" className="close" onClick={this.handleDismiss}>
					</button>
					<i className="fa-lg fa fa-warning" style={{marginRight: 2 + 'px'}}></i>
					{this.state.error}
					</div>
		        <div className="form-actions">
		            <button className="btn btn-block wide-btn green uppercase" disabled={this.state.disableSubmit}>Войти</button>
		        </div>
			</form>
		);
	}
}

export default LoginForm;